import React from "react"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div className="resp-container">
      <iframe
        className="resp-iframe"
        src="https://www.youtube.com/embed/gNDnR430ZYs?start=24&autoplay=1&loop=1&playlist=gNDnR430ZYs"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </Layout>
)

export default IndexPage
